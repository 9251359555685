import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className="home-main-slides">
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/1.jpg"
          src="./images/11.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="slide-h3">Wanway Solutions Ltd</h3>
          <p className="slide-p slide-p1">We Track It For You</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/43.jpeg"
          src="./images/13.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p"> Providing Vehicle Tracking Services</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/53.jpeg"
            src="./images/10.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p3">
           We Do Fleet Management
          </p>
        </Carousel.Caption>

      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/4.jpg"
          alt="Third slide"
        />

          <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
         Trust us for anti-theft solutions!
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
      
    </Carousel>
  );
}

export default CarouselFade;